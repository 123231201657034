//根据类型,key获取name

export function getNameByKey(type, key) {
    let name = ''
    Object.values(type).forEach(d => {
        d.key === key && (name = d.name)
    })
    return name
}


export const DICT_AUDIT_STATUS = {
    EDIT: {name: "未提交", key: "0"},
    WAIT: {name: "待审核", key: "10"},
    PASS: {name: "审核通过", key: "20"},
    BACK: {name: "被驳回", key: "99"}
}

export const DICT_YES_OR_NO = {
    YES: {name: "是", key: "true"},
    NO: {name: "否", key: "false"}
}

export const DICT_USE = {
    ENABLE: {name: "启用", key: "true"},
    DISABLE: {name: "禁用", key: "false"}
}

export const DICT_OPEN_OR_CLOSE = {
    OPEN: {name: "开启", key: "true"},
    CLOSE: {name: "关闭", key: "false"}
}

export const DICT_EFFECT = {
    EFFECTIVE: {name: "有效", key: "true"},
    INEFFECTIVE: {name: "无效", key: "false"}
}

export const DICT_SORT = {
    ASC: {name: "正序", key: "asc"},
    DESC: {name: "倒叙", key: "desc"},
    NO: {name: "无", key: "no"}
}

export const DICT_TYPE_ORGAN = {
    ORGAN: {name: "机构", key: "1"},
    DEPT: {name: "部门", key: "2"}
}

export const DICT_TYPE_USER_ORGAN_LX = {
    SH: {name: "商户", key: "1"},
    MD: {name: "门店", key: "2"}
}

export const DICT_TYPE_YFK_OPERATION = {
    ZJ: {name: "增加", key: "1"},
    JS: {name: "减少", key: "2"}
}

export const DICT_TYPE_COLLECTION = {
    WX: {name: "微信", key: "1"},
    ZFB: {name: "支付宝", key: "2"},
    XX: {name: "线下", key: "3"},
    YHK: {name: "银行卡", key: "4"},
}

export const DICT_TYPE_PAYMENT = {
    YFK: {name: "预付款支付", key: "1"},
    WX: {name: "微信", key: "2"},
    ZFB: {name: "支付宝", key: "3"},
    XX: {name: "线下", key: "4"},
    YHK: {name: "银行卡", key: "5"},
}

export const DICT_TYPE_COLLECTION_STATUS = {
    DSK: {name: "待收款", key: "1"},
    BFSK: {name: "部分收款", key: "2"},
    YSK: {name: "已收款", key: "3"}
}

export const DICT_TYPE_PAYMENT_STATUS = {
    DFK: {name: "待付款", key: "1"},
    BFFK: {name: "部分付款", key: "2"},
    YFK: {name: "已付款", key: "3"}
}

export const DICT_TYPE_PAN_DIAN_STATUS = {
    WPD: {name: "未盘点", key: "1"},
    PDZ: {name: "盘点中", key: "2"},
    YPD: {name: "已盘点", key: "3"}
}
export const DICT_TYPE_DIAO_BO_STATUS = {
    WKS: {name: "未开始", key: "1"},
    JXZ: {name: "进行中", key: "2"},
    YJS: {name: "已结束", key: "3"}
}

export const DICT_TYPE_CAI_GOU_TYPE = {
    SYLX: {name: "所有类型", key: ""},
    XZCG: {name: "新增采购", key: "1"},
    BCGD: {name: "补采购单", key: "2"},
    QTCG: {name: "其他采购", key: "3"},
}

export const DICT_TYPE_TIAO_JIA_TYPE = {
    CSHRK: {name: "初始化入库", key: "1"},
    CGD: {name: "采购单", key: "2"},
    QTRK: {name: "其他入库", key: "3"},
}

export const DICT_TYPE_JING_YING_FS = {
    LS: {name: "零售", key: "1"},
    PF: {name: "批发", key: "2"},
    QT: {name: "其他", key: "3"},
}

export const TYPE_RU_KU_L_X = {
    SYLX: {name: "所有类型", key: ""},
    CSHRK: {name: "初始化入库", key: "0"},
    CGRK: {name: "采购入库", key: "1"},
    BHRK: {name: "补货入库", key: "2"},
    DBRK: {name: "调拨入库", key: "3"},
    PYRK: {name: "盘盈入库", key: "4"},
    QTRK: {name: "其他入库", key: "5"},
    SQDBRK: {name: "申请调拨入库", key: "6"},
    JSRK: {name: "拒收入库", key: "7"},
    SHDBRK: {name: "商户调拨入库", key: "8"},
    MDDBRK: {name: "门店调拨入库", key: "9"},
    MDTHRK: {name: "门店退货入库", key: "10"},
    SYTHRK: {name: "收银退货入库", key: "11"},
}

export const TYPE_RU_KU_STATUS = {
    WCKQR: {name: "未出库确认", key: "0"},
    WSH: {name: "未审核", key: "1"},
    WRK: {name: "未入库", key: "2"},
    YRK: {name: "已入库", key: "3"},
    YJJ: {name: "已拒绝", key: "4"},
    SHJJ: {name: "审核拒绝", key: "5"},
    BFRK: {name: "部分入库", key: "6"},
    YJSRK: {name: "已拒收入库", key: "7"},
}

export const TYPE_CHU_KU_LX = {
    DBCK: {name: "调拨出库", key: "1"},
    SQDBCK: {name: "申请调拨出库", key: "2"},
    THZGHSCK: {name: "退货至供货商出库", key: "3"},
    THZSHCK: {name: "退货至商户出库", key: "4"},
    PKCK: {name: "盘亏出库", key: "5"},
    QTCK: {name: "其他出库", key: "6"},
    XSCK: {name: "销售出库", key: "7"},
    ZPCK: {name: "赠品出库", key: "8"},
}

export const TYPE_HUO_LIU_STATUS = {
    SQ: {name: "申请", key: "1"},
    YCK: {name: "已出库", key: "2"},
    YRK: {name: "已入库", key: "3"},
    YJJ: {name: "已拒绝", key: "4"},
    BFRK: {name: "部分入口", key: "5"},
    YJSRK: {name: "已拒收入库", key: "6"},
    WSH: {name: "未审核", key: "7"},
}

export const TYPE_HUO_LIU_SHEN_HE_STATUS = {
    SQ: {name: "申请", key: "1"},
    SHJJ: {name: "审核拒绝", key: "2"},
    SHTGYRK: {name: "审核通过已入库", key: "3"},
    SHTGWRK: {name: "审核通过未入库", key: "4"},
    YJJ: {name: "已拒收", key: "5"},
    YQR: {name: "已确认", key: "6"},
}

export const TYPE_ROLE = {
    CJGLY: {name: "超级管理员", key: "99"},
    DZ: {name: "店长", key: "1"},
}




export const DICT_TYPE_CHONG_ZHI_WAY = {
    WX: {name: "微信支付", key: "1"},
    ZFB: {name: "支付宝支付", key: "2"},
    XJZF: {name: "现金支付", key: "3"},
}

export const DICT_TYPE_HUI_YUAN_LX = {
    CGHY: {name: "常规会员", key: "1"},
    PLUSHY: {name: "PLUS会员", key: "2"},
}

export const DICT_TYPE_KA_ZT = {
    ZC: {name: "正常", key: "1"},
    YDJ: {name: "已冻结", key: "2"},
    YZX: {name: "已注销", key: "3"},
}

export const DICT_TYPE_ZHUXIAO_ZT = {
    YZX: {name: "已注销", key: "true"},
    WZX: {name: "未注销", key: "false"},
}

export const DICT_TYPE_DEL_ZT = {
    YSC: {name: "已删除", key: "true"},
    WSC: {name: "未删除", key: "false"},
}

export const DICT_TYPE_CZZT = {
    YDZ: {name: "已到账", key: "1"},
    YTK: {name: "已退款", key: "2"},
}

export const DICT_TYPE_DDJLZT = {
    YZF: {name: "已支付", key: "1"},
    YTK: {name: "已退款", key: "2"},
}

export const DICT_TYPE_FENXI_JINE = {
    BXJE: {name: "不限金额", key: "1"},
    DYJD: {name: "0-100", key: "2"},
    DEJD: {name: "101-500", key: "3"},
    DSJD: {name: "501-2000", key: "4"},
}

export const DICT_TYPE_FENXI_COUNT = {
    BXJE: {name: "不限次数", key: "1"},
    DYJD: {name: "0-3次", key: "2"},
    DEJD: {name: "4-10次", key: "3"},
    DSJD: {name: "11-20次", key: "4"},
}

//此组件用于回显各种根据单位代码生成的子孙代码 含库区、仓房、廒间、货位、货位卡等
export const lens = {
    dwdm: {key: "dwdm", len: 18, start: 0, end: 18},
    kqdm: {key: "kqdm", len: 21, start: 18, end: 21},
    cfdm: {key: "cfdm", len: 25, start: 21, end: 25},
    ajdm: {key: "ajdm", len: 28, start: 25, end: 28},
    hwdm: {key: "hwdm", len: 30, start: 28, end: 30},
    hwkdm: {len: 36, yearStart: 30, yearEnd: 34, numberStart: 34, numberEnd: 36},
    crkdm: {len: 12, headStart: 0, headEnd: 2, timeStart: 2, timeEnd: 8, numberStart: 8, numberEnd: 12},
    crkjs: {len: 33, timeStart: 21, timeEnd: 29, numberStart: 29, numberEnd: 33},
    rkzldh: {len: 14, headStart: 0, headEnd: 4, timeStart: 4, timeEnd: 10, numberStart: 10, numberEnd: 14},
    sxwck: {len: 15, headStart: 0, headEnd: 5, timeStart: 5, timeEnd: 11, numberStart: 11, numberEnd: 15}
}

export const SPXX_BATCH_SET_BALUE = {
    batchOptions: [
        {
            value: 1,
            label: "进价",
            disabled: false,
        },
        {
            value: 2,
            label: "零售价",
            disabled: false,
        },
        {
            value: 3,
            label: "预警"
        },
    ]
}

export const JYGL_SKMX_CARD_SUFFIX = {
    BI: {name: "(笔)", key: "bi"},
    YUAN: {name: "(元)", key: "yuan"},
    BFH:{name:"%",key:"bfh"}
}
export const KONG_ZHI_XIAN_SHI = {
    WSZ: {name: "未设置", key: "wsz"},
    WML: {name: "未抹零", key: "wml"},
    WU: {name: "无", key: "wu"}
}

export const DICT_TYPE_QHY_ZT = {
    WKS: {name: "未开始", key: "1"},
    JXZ: {name: "进行中", key: "2"},
    YJS: {name: "已经结", key: "3"}
}

export const DICT_TYPE_SP_SEARCH_CONDITION = {
    HH: {name: "货号", key: "1"},
    TM: {name: "条码", key: "2"},
    MC: {name: "名称", key: "3"}
}

export const DICT_TYPE_PERIOD = {
    RXS: {name: "日销售", key: "1"},
    ZXS: {name: "周销售", key: "2"},
    YXS: {name: "月销售", key: "3"},
    NXS: {name: "年销售", key: "4"}
}

export const DICT_TYPE_BDCJ = {
    SYD: {name: "收银端", key: "1"},
    MDHT: {name: "门店后台", key: "2"},
}

export const DICT_TYPE_ORDERS = {
    GMDD: {name: "购买订单", key: "1"},
    THDD: {name: "退货订单", key: "2"},
    YTK: {name: "有退款", key: "3"},
}

export const DICT_TYPE_SHANG_PIN_SHU_XING = {
    MC: {name: "名称", key: "shangPinMC"},
    FL: {name: "分类", key: "shangPinLeiBie"},
    FLMC: {name: "分类名称", key: "fenLeiMC"},
    TP: {name: "图片", key: "shangPinFengMianTu"},
    HH: {name: "货号", key: "shangPinHuoHao"},
    TM: {name: "条码", key: "shangPinTiaoMa"},
    DW: {name: "单位", key: "shangPinDW"},
    GHS: {name: "供货商", key: "gongHuoShang"},
    GHSMC: {name: "供货商名称", key: "gongHuoShangMC"},
    PP: {name: "品牌", key: "shangPinPinPai"},
    PPMC: {name: "品牌名称", key: "shangPinPinPaiMC"},
    CD: {name: "产地", key: "shangPinChanDi"},
    XQ: {name: "详情", key: "shangPinXiangQing"},
    LSJ: {name: "零售价", key: "dianNeiLSJ"},
    CSLX: {name: "出售类型", key: "shiFuDianNeiChuShou"},
    CZLX: {name: "称重类型", key: "shiFuChengZhong"},
    SPZT: {name: "商品状态", key: "shangPinZT"},
    GGHH:{name: "规格货号", key: "guiGeHuoHao"},
    GGTM:{name: "规格条码", key: "guiGeTiaoMa"},
    GGLSJ:{name: "规格商品零售价", key: "guiGeShangPinLSJ"},
}

export const DICT_TYPE_FU_PING_SET = {
    YZPYZ:{name: "与主屏一致", key: "1"},
    TP:{name: "图片", key: "2"},
}

export const DICT_TYPE_XIAO_PIAO = {
    SYXP:{name:"收银小票",key:"1"},
    CZXP:{name:"充值小票",key:"2"}
}

export const DICT_TYPE_XIAO_PIAO_MO_KUAI = {
    DM:{name:"店名",key:"1"},
    BT:{name:"标题",key:"2"},
    SPXX:{name:"商品信息",key:"3"},
    KHXX:{name:"客户信息",key:"4"},
    ZFXX:{name:"支付信息",key:"5"},
    HYCZ:{name:"会员充值",key:"6"},
    FZX:{name:"辅助线",key:"7"},
    DPTG:{name:"店铺推广",key:"8"},
    WB:{name:"文本",key:"9"},
}

export const YING_XIAO_FANG_SHI = {
    JYDD:{name:"基于订单",key:"1"},
    JYSP:{name:"基于商品",key:"2"}
}

export const DICT_TAB_PANE_NAME = {
    PT:{name:"普通",key:"1"},
    GG:{name:"规格",key:"2"}
}




