<template>
    <div class="x-page-container" ref="resize">
        <el-tabs @tab-click="kuCunClick" v-model="baoBiaoTabName">
            <el-tab-pane label="普通商品" :name="dictTabPane.PT.key">
                <div class="x-page-search">
                    <x-search-item label="商品信息">
                        <el-select size="small" v-model="searchSelect" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in compoundOptions" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                        <el-input v-model="searChParamMid" size="small" placeholder="请输入">
                        </el-input>
                    </x-search-item>
                    <x-search-item label="门店">
                        <el-select size="small" v-model="search.guiShuMenDian" placeholder="选择门店">
                            <el-option v-for="item in menDianList" :key="item.id" :label="item.menDianMC" :value="item.id">
                            </el-option>
                        </el-select>
                    </x-search-item>
                    <div class="x-search-item" style="margin-left: 5px">
                        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                        <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                    </div>
                </div>
                <el-table ref="tableFour" v-loading="table.loading" :data="table.data" :height="table.height" border>
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column prop="shangPinMC" label="商品名称"/>
                    <el-table-column prop="shangPinHuoHao" label="货号"/>
                    <el-table-column prop="shangPinTiaoMa" label="条码"/>
                    <el-table-column prop="shangPinKuCun" label="库存"/>
                    <el-table-column width="250" align="center" label="操作" fixed="right">
                        <template slot-scope="{ row, $index }">
                            <el-button type="primary" size="mini" round @click="kuCunBaoBiaoDetailShow(row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </el-tab-pane>
            <el-tab-pane label="规格商品" :name="dictTabPane.GG.key">
                <div class="x-page-search">
                    <x-search-item label="商品信息">
                        <el-select size="small" v-model="searchSelect" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in compoundOptions" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                        <el-input v-model="searChParamMid" size="small">
                        </el-input>
                    </x-search-item>
                    <x-search-item label="门店">
                        <el-select size="small" v-model="search.guiShuMenDian" placeholder="选择门店">
                            <el-option v-for="item in menDianList" :key="item.id" :label="item.menDianMC" :value="item.id">
                            </el-option>
                        </el-select>
                    </x-search-item>
                    <div class="x-search-item" style="margin-left: 5px">
                        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                        <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                    </div>
                </div>
                <el-table ref="tableFive" v-loading="table.loading" :data="table.data" :height="table.height" border>
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column prop="shangPinMC" label="商品名称"/>
                    <el-table-column prop="ciJiGuiGeMC" label="规格"/>
                    <el-table-column prop="guiGeHuoHao" label="货号"/>
                    <el-table-column prop="guiGeTiaoMa" label="条码"/>
                    <el-table-column prop="guiGeShangPinKuCun" label="库存"/>
                    <el-table-column width="250" align="center" label="操作" fixed="right">
                        <template slot-scope="{ row, $index }">
                            <el-button type="primary" size="mini" round @click="kuCunBaoBiaoDetailShow(row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </el-tab-pane>
        </el-tabs>
        <ku-cun-bao-biao-detail ref="kuCunBaoBiao"/>
    </div>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import XTableBase from "@/components/x/XTableBase";
    import KuCunBaoBiaoDetail from "@/view/mdgl/tjbb/KuCunBaoBiaoDetail";
    import {checkboxList} from "@/service/mdgl/MenDianXX"
    import {DICT_YES_OR_NO,DICT_TAB_PANE_NAME} from "@/util/constant"


    export default {
        name: "KuCunBaoBiaoTongJi",
        mixins: [XTableBase],
        components: {KuCunBaoBiaoDetail},
        data(){
            this.refreshService = service.kuCunBaoBiaoList;
            this.service = service;
            return {
                search: {
                    shangPinMC: '',
                    shangPinHuoHao: '',
                    shangPinTiaoMa: '',
                    guiShuMenDian: '',
                    shiFuDuoGuiGe:"false"
                },
                baoBiaoTabName:DICT_TAB_PANE_NAME.PT.key,
                dictTabPane:DICT_TAB_PANE_NAME,
                searchSelect: 1,
                searChParamMid: '',
                menDianList:[],
                compoundOptions: [
                    {id: 1, name: '名称'},
                    {id: 2, name: '货号'},
                    {id: 3, name: '条码'},
                ],
            }
        },
        mounted() {
            this.getMenDianSelectList()
        },
        methods:{
            getMenDianSelectList(){
                checkboxList().then((res)=>{
                    this.menDianList = res.data
                })
            },
            handleClear(){
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                if(this.baoBiaoTabName===DICT_TAB_PANE_NAME.PT.key){
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.NO.key
                }else{
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.YES.key
                }
            },
            kuCunBaoBiaoDetailShow(row){
                if(row.shangHuShangPinId){
                    this.$refs.kuCunBaoBiao.search.shangHuShangPinId = row.shangHuShangPinId
                }else{
                    this.$refs.kuCunBaoBiao.search.shangHuShangPinId = row.id
                }
                if(this.baoBiaoTabName == DICT_TAB_PANE_NAME.PT.key){
                    this.$refs.kuCunBaoBiao.title = row.shangPinMC+"-详情"
                }else{
                    this.$refs.kuCunBaoBiao.title = row.shangPinMC+"("+row.ciJiGuiGeMC+")"+"-详情"
                }
                this.$refs.kuCunBaoBiao.showDisabled = true
            },
            kuCunClick(tab, event) {
                if(tab.index==0){
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.NO.key
                }else{
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.YES.key
                }
                this.refresh()
            },
        }
    }
</script>

<style scoped>

</style>
