import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/men-dian-xx/list',
        method: 'post',
        data
    })
}

//预警时间设置
export function yuJing(data) {
    return request({
        url: '/men-dian-xx/yu-jing',
        method: 'post',
        data
    })
}

//获取当前门店信息（收银员）
export function getNowMenDian() {
    return request({
        url: '/men-dian-xx/get-now-men-dian',
        method: 'get',
    })
}

//门店信息多选列表
export function checkboxList() {
    return request({
        url: '/men-dian-xx/checkbox-list',
        method: 'get',
    })
}
//商户设置当前门店信息至redis中
export function setMenDianXXToRedis(id) {
    return request({
        url: `/men-dian-xx/set-men-dian-to-redis/${id}`,
        method: 'post',
    })
}
//从redis中获取当前门店信息
export function getCurrentMenDianXXFromRedis() {
    return request({
        url: '/men-dian-xx/get-current-men-dian-from-redis',
        method: 'get',
    })
}

//清除门店信息
export function clearMenDianXXFromRedis() {
    return request({
        url: '/men-dian-xx/clear-men-dian-to-redis',
        method: 'get',
    })
}

//门店开启/关闭
export function openShutDownMenDian(data) {
    return request({
        url: '/men-dian-xx/open-shut-down',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/men-dian-xx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/men-dian-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/men-dian-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/men-dian-xx/update',
        method: 'post',
        data
    })
}

//设置主店
export function setZhuDian(data) {
    return request({
        url: '/men-dian-xx/set-zhu-dian',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/men-dian-xx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/men-dian-xx/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/men-dian-xx/query',
        method: 'post',
        data
    })
}

//店长列表
export function getDianZhangList(data) {
    return request({
        url: '/men-dian-xx/get-dian-zhang-list',
        method: 'post',
        data
    })
}

//添加店长
export function dianZhangAdd(data) {
    return request({
        url: '/men-dian-xx/dian-zhang-add',
        method: 'post',
        data
    })
}

//获取店长编辑信息
export function getDianZhangUpdate(id) {
    return request({
        url: `/men-dian-xx/get-dian-zhang-update/${id}`,
        method: 'get',
    })
}

//店长编辑保存
export function updateMenDianDianZhang(data) {
    return request({
        url: '/men-dian-xx/update-dian-zhang',
        method: 'post',
        data
    })
}

//删除店长
export function deleteMenDianDianZhang(id) {
    return request({
        url: `/men-dian-xx/delete-dian-zhang/${id}`,
        method: 'post',
    })
}



/*
列表路由
{
    path: 'men-dian-xx-list',
    component: () => import('@/view/mdgl/mdxx/MenDianXXList'),
    name: 'MenDianXXList',
    meta: {title: '门店信息', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'men-dian-xx-query',
    component: () => import('@/view/mdgl/mdxx/MenDianXXQuery'),
    name: 'MenDianXXQuery',
    meta: {title: '门店信息查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'men-dian-xx-remind-list',
    component: () => import('@/view/mdgl/mdxx/MenDianXXRemindList'),
    name: 'MenDianXXRemindList',
    meta: {title: '门店信息提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"门店信息基础列表","methodUrl":"/men-dian-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"门店信息添加保存","methodUrl":"/men-dian-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"门店信息获取编辑数据","methodUrl":"/men-dian-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"门店信息获取详情数据","methodUrl":"/men-dian-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"门店信息编辑保存","methodUrl":"/men-dian-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"门店信息删除","methodUrl":"/men-dian-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"门店信息批量删除","methodUrl":"/men-dian-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"门店信息独立查询","methodUrl":"/men-dian-xx/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
