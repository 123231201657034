<template>
    <el-dialog :title="title" :visible.sync="showDisabled" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="500px">
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="menDianMC" label="门店"/>
            <el-table-column prop="kucun" label="数量">
                <template slot-scope="{ row, $index }">
                    <span v-if="row.shiFuDuoGuiGe=='true'">{{row.guiGeShangPinKuCun}}</span>
                    <span v-else>{{row.shangPinKuCun}}</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </el-dialog>
</template>

<script>
    import XTableBase from "@/components/x/XTableBase";
    import * as service from "@/service/spgl/ShangPinXX";

    export default {
        name: "KuCunBaoBiaoDetail",
        mixins: [XTableBase],
        beforeCreate() {
        },
        mounted() {
        },
        data(){
            this.refreshService = service.kuCunBaoBiaoDetailList;
            this.service = service;
            return {
                title:"",
                showDisabled:false,
                search:{
                    shangHuShangPinId:"",
                }
            }
        },
        methods:{
            open(){
                this.refresh()
            },
        }
    }
</script>

<style scoped>

</style>
