import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shang-pin-xx/list',
        method: 'post',
        data
    })
}
//商品概况列表
export function getGaiKuanglist(data) {
    return request({
        url: '/shang-pin-xx/get-gai-kuang-list',
        method: 'post',
        data
    })
}
//临期列表
export function linQilist(data) {
    return request({
        url: '/ku-cun-zhuang-kuang/lin-qi-list',
        method: 'post',
        data
    })
}


//滞销商品列表
export function getZhiXiaoList(data) {
    return request({
        url: '/shang-pin-xx/get-zhi-xiao-list',
        method: 'post',
        data
    })
}
//获取商品信息列表
export function getShangPinXXList() {
    return request({
        url: '/shang-pin-xx/select-list',
        method: 'post',
    })
}


//统计信息--库存统计列表
export function kuCunTongJiList(data) {
    return request({
        url: '/shang-pin-xx/ku-cun-tong-ji-list',
        method: 'post',
        data
    })
}

//统计信息--库存统计-详情列表
export function kuCunTongJiDetailList(data) {
    return request({
        url: '/shang-pin-xx/ku-cun-tong-ji-detail-list',
        method: 'post',
        data
    })
}

//统计信息--库存报表列表
export function kuCunBaoBiaoList(data) {
    return request({
        url: '/shang-pin-xx/ku-cun-bao-biao-list',
        method: 'post',
        data
    })
}

//统计信息--库存报表-详情列表
export function kuCunBaoBiaoDetailList(data) {
    return request({
        url: '/shang-pin-xx/ku-cun-bao-biao-detail-list',
        method: 'post',
        data
    })
}

//商品同步列表
export function syncList(data) {
    return request({
        url: '/shang-pin-xx/sync-list',
        method: 'post',
        data
    })
}

//更新商品列表
export function getUpdateList(data) {
    return request({
        url: '/shang-pin-xx/get-update-list',
        method: 'post',
        data
    })
}

//更新商品集合信息
export function getAllUpdateSpList(data) {
    return request({
        url: '/shang-pin-xx/get-all-update-sp-list',
        method: 'post',
        data
    })
}

//批量更新商品
export function batchUpdateShSp(data) {
    return request({
        url: '/shang-pin-xx/batch-update-sh-sp',
        method: 'post',
        data
    })
}



//回收站列表
export function recycleBinList(data) {
    return request({
        url: '/shang-pin-xx/recycle-bin-list',
        method: 'post',
        data
    })
}

//新增赠品商品列表
export function giveawayAddList(data) {
    return request({
        url: '/shang-pin-xx/giveaway-add-list',
        method: 'post',
        data
    })
}

//赠品商品列表
export function giveawayList(data) {
    return request({
        url: '/shang-pin-xx/giveaway-list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/shang-pin-xx/add',
        method: 'post',
        data
    })
}

//添加保存
export function giveawayAdd(data) {
    return request({
        url: '/shang-pin-xx/giveaway-add',
        method: 'post',
        data
    })
}

//批量同步商品
export function batchSyncAdd(data) {
    return request({
        url: '/shang-pin-xx/batch-sync-add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/shang-pin-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取5位数货号
export function getItemNumber() {
    return request({
        url: "/shang-pin-xx/get-item-number",
        method: 'get',
    })
}

//获取10位数条码
export function getBarcode() {
    return request({
        url: "/shang-pin-xx/get-barcode",
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shang-pin-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shang-pin-xx/update',
        method: 'post',
        data
    })
}

//赠品库库存设置
export function giveawayUpdate(data) {
    return request({
        url: '/shang-pin-xx/giveaway-update',
        method: 'post',
        data
    })
}

//商品状态调整
export function statusUpdate(data) {
    return request({
        url: '/shang-pin-xx/status_update',
        method: 'post',
        data
    })
}

//赠品状态调整
export function giveawayStatusUpdate(data) {
    return request({
        url: '/shang-pin-xx/giveaway-status-update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shang-pin-xx/delete/${id}`,
        method: 'post',
    })
}

//赠品库删除
export function giveawayDelete(id) {
    return request({
        url: `/shang-pin-xx/giveawayDelete-delete/${id}`,
        method: 'post',
    })
}

//从回收站中恢复
export function recover(id) {
    return request({
        url: `/shang-pin-xx/recover/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shang-pin-xx/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/shang-pin-xx/query',
        method: 'post',
        data
    })
}

//根据仓库名称查询商品信息
export function getShangPinXXByCkmc(cangKuMC) {
    return request({
        url: `/shang-pin-xx/get-spxx-by-ckmc/${cangKuMC}`,
        method: 'get',
    })
}

//基础列表
export function listAllGuiGe(data) {
    return request({
        url: '/shang-pin-xx/listAllGuiGe',
        method: 'post',
        data
    })
}
// 商品概况  czy
export function getShangPinGaiKuang(data){
    return request({
        url: '/ku-cun-zhuang-kuang/gai-kuang',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shang-pin-xx-list',
    component: () => import('@/view/spgl/spxx/ShangPinXXList'),
    name: 'ShangPinXXList',
    meta: {title: '商品信息', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'shang-pin-xx-query',
    component: () => import('@/view/spgl/spxx/ShangPinXXQuery'),
    name: 'ShangPinXXQuery',
    meta: {title: '商品信息查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'shang-pin-xx-remind-list',
    component: () => import('@/view/spgl/spxx/ShangPinXXRemindList'),
    name: 'ShangPinXXRemindList',
    meta: {title: '商品信息提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"商品信息基础列表","methodUrl":"/shang-pin-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"商品信息添加保存","methodUrl":"/shang-pin-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"商品信息获取编辑数据","methodUrl":"/shang-pin-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"商品信息获取详情数据","methodUrl":"/shang-pin-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"商品信息编辑保存","methodUrl":"/shang-pin-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"商品信息删除","methodUrl":"/shang-pin-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"商品信息批量删除","methodUrl":"/shang-pin-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"商品信息独立查询","methodUrl":"/shang-pin-xx/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
