<script>
import {DICT_AUDIT_STATUS, DICT_EFFECT, DICT_USE, DICT_YES_OR_NO} from '@/util/constant'
import bjggs from "@/util/bjggs";

export default {
    data() {
        this.searchNoClear = {current: 1, size: 10};
        this.sels = [];
        this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
        return {
            openHs: false,
            table: {
                loading: false,
                data: [],
                total: 0,
                height: 500
            },
            detail: {
                visible: false,
                id: ''
            },
            editor: {
                visible: false,
                id: null,
                isUpdate: false
            },
            print: {
                visible: false,
                id: null,
                isUpdate: false
            }

        }
    },
    mounted() {
        this.refresh();
    },
    activated() {
        this.$route.params.refresh && this.refresh();
        //解决fixed导致表格错位 激活页面重新计算表格布局
        this.$nextTick(() => {
            this.$refs.table.doLayout()
        })
    },
    methods: {
        refresh() { //默认刷新列表方法
            this.table.loading = true;
            return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                let ret = response.data;
                this.table.data = ret.records;
                this.table.total = ret.total || 0;
                //处理11条数据删除一条不显示问题
                if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                    this.searchNoClear.current--
                    this.refresh()
                    return
                }
                return response;
            }).catch(error => {
                throw error;
            }).finally(() => this.table.loading = false)
        },
        handleSizeChange(val) { //默认当前页大小变化
            this.searchNoClear.size = val;
            return this.refresh();
        },
        handleCurrentChange(val) { //默认当前页变化
            this.searchNoClear.current = val;
            return this.refresh();
        },
        handleSearch() { //默认查询
            this.searchNoClear.current = 1;
            return this.refresh();
        },
        handleClear() { //默认清空方法
            Object.keys(this.search).forEach((key) => this.search[key] = '')
        },
        handleSelectionChange(sels) { //默认带有复选框的类表，复选变化触发
            this.sels = sels;
        },
        getIds() { //获取所有复选ID
            if (!this.sels || this.sels.length < 1) {
                this.$message.warning("请至少选择一条数据！");
                return false;
            }
            return this.sels.map(obj => obj.id);
        },
        getIdsFilter(filter) {
            if (!this.sels || this.sels.length < 1) {
                this.$message.warning("请至少选择一条数据！");
                return false;
            }
            return this.sels.filter((o) => {
                return filter(o);
            }).map(obj => obj.id);
        },
        handleAdd() {
            this.editor.isUpdate = false;
            this.editor.id = null;
            this.editor.visible = true;
        },
        handleUpdate(id) {
            this.editor.isUpdate = true;
            this.editor.id = id;
            this.editor.visible = true;
        },
        handleToDetail(id) {
            this.detail.visible = true;
            this.detail.id = id;
        },
        handleToPrint(id) {
            this.print.visible = true;
            this.print.id = id;
        },
        handleDelete(id) {
            this.service.del(id).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },
        handleDeleteBatch() {
            let ids = this.getIds();
            if (ids) {
                this.service.deleteBatch({ids: ids}).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            }
        },
        handleConfirm(id) {
            this.service.confirm(id).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },

        handleEnable(id) {
            this.service.use({id: id, use: DICT_USE.ENABLE.key}).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },
        handleDisable(id) {
            this.service.use({id: id, use: DICT_USE.DISABLE.key}).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },
        handleEffective(id) {
            this.service.effect({id: id, effect: DICT_EFFECT.EFFECTIVE.key}).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },
        handleIneffective(id) {
            this.service.effect({id: id, effect: DICT_EFFECT.INEFFECTIVE.key}).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },
        handleAudit(id) {
            this.service.audit({id: id, auditStatus: DICT_AUDIT_STATUS.WAIT.key}).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
            });
        },
        isWaitOrPass(auditStatus) {
            return auditStatus === DICT_AUDIT_STATUS.WAIT.key || auditStatus === DICT_AUDIT_STATUS.PASS.key;
        },
        isUse(use) {
            return use === DICT_USE.ENABLE.key;
        },
        isEffect(effect) {
            return effect === DICT_EFFECT.EFFECTIVE.key;
        },
        isPass(auditStatus) {
            return auditStatus === DICT_AUDIT_STATUS.PASS.key
        },
        isConfirm(confirm) {
            return confirm === DICT_YES_OR_NO.YES.key;
        },
        //是否开启同步
        isSyncOpen() {
            return bjggs.IS_SYNC_OPEN;
        }
    }
}
</script>
